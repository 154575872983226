import React from 'react';
import classNames from 'classnames';

const LoadingButton = ({text, icon, primary, secondary, danger, disabled, isLoading, small, extraSmall, onClick, defaultClass, type}) => (
  <button className={classNames('button', {
    primary,
    secondary,
    small,
    'extra-small': extraSmall,
    danger,
    default: defaultClass
  })} disabled={disabled || isLoading} onClick={(e) => onClick && onClick(e)} type={type}>
    <i className={icon}/>
    <span>{text}</span>
    {
      isLoading &&
      <div className='loading-button'>
        <div className="spinner"></div>
      </div>
    }
  </button>
);

export default LoadingButton;
