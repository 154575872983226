import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {validateForm} from './validate';
import classNames from 'classnames';
import LoadingButton from "../../ui/LoadingButton";
import FormTypePicker from "../../forms/FormTypePicker";
import Checkbox from "../../forms/Checkbox";
import UploadUserPhoto from '../../../subscriber-app/components/users/UploadUserPhoto';
import ColorLabels from "./ColorLabels";
import Select from 'react-select'
import isObject from "lodash-es/isObject";

const groupStyles = {
  backgroundColor: '#EBECF0',
  color: '#172B4D',
  fontSize: 16,
  fontWeight: 'bold',
  lineHeight: '1',
  minWidth: 1,
  padding: '4px',
  textAlign: 'left',
};

const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
  </div>
);


const groupOptions = (field, props) => {
  let options = field.options;

  let groups = field.options.filter(o => o.label && o.label.startsWith("***") && o.label.endsWith("***"))

  if(groups.length > 0) {
    let currentGroup = null;
    let groupsResult = []

    for(let i = 0; i < options.length; i ++) {
      let option = options[i];
      if(groups.find(g => g.label === option.label)) {
        currentGroup = {
          label: option.label.replace(/\*\*\*/g, ""),
          options: []
        }

        groupsResult.push(currentGroup)
      } else {
        if(!currentGroup) {
          currentGroup = {
            options: []
          }

          groupsResult.push(currentGroup)
        }

        currentGroup.options.push(option)
      }
    }

    return groupsResult;
  }

  return options;
}

const getSelectOptions = (field, props) => {
  if(typeof field.options === 'function') {
    return field.options(props);
  } else {
    let fieldOptions = groupOptions(field, props)
      .map(o => {
        if(isObject(o)) {
          return o
        }

        return {
          label: o,
          value: o
        }
      })

    if(field.sorted === false) {
      return fieldOptions
    }

   return fieldOptions.sort((q1, q2) => {
      let collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});
      let sortPropA = q1.label;
      let sortPropB = q2.label;
      return collator.compare(sortPropA, sortPropB);
    });
  }
};

const getScoreOptions = (field) => {
  let score = field.score;
  let options = []
  for (let i = 1; i <= score; i ++) {
    options.push({
      value: i,
      label: i
    })
  }
  return options
};

const getSelectValue = (value, options, field) => {
  return options.find(o => {
    let val = field.value? o[field.value]: o.value
    return val === value
  })
}

const getOptionValue = (option, field) => {
  return field.value? option[field.value]: option.value
}
const getFieldByType = (field, fieldKey, value, onChange, onBlur, props, isInvalid) => {
  switch (field.type) {
    case 'text':
    case 'number':
      return (
        <input
          className={classNames('form-control', {
            'is-invalid': isInvalid
          })}
          type={field.type}
          placeholder={field.placeholder || field.label}
          id={fieldKey}
          value={value}
          onChange={(e) => onChange(e.target.value, fieldKey)}
          onBlur={(e) => onBlur(e.target.value, fieldKey)}/>
      );
    case 'password':
      return (
        <input
          className={classNames('form-control', {
            'is-invalid': isInvalid
          })}
          type="password"
          placeholder={field.placeholder || field.label}
          id={fieldKey}
          value={value}
          onChange={(e) => onChange(e.target.value, fieldKey)}
          onBlur={(e) => onBlur(e.target.value, fieldKey)}/>
      );
    case 'time':
      return (
        <input
          className={classNames('form-control', {
            'is-invalid': isInvalid
          })}
          type="time"
          placeholder={field.placeholder || field.label}
          id={fieldKey}
          value={value}
          onChange={(e) => onChange(e.target.value, fieldKey)}
          onBlur={(e) => onBlur(e.target.value, fieldKey)}/>
      );
    case 'date':
      return (
        <input
          className={classNames('form-control', {
            'is-invalid': isInvalid
          })}
          type="date"
          placeholder={field.placeholder || field.label}
          id={fieldKey}
          value={value}
          onChange={(e) => onChange(e.target.value, fieldKey)}
          onBlur={(e) => onBlur(e.target.value, fieldKey)}/>
      );
    case 'textarea':
      return (
        <textarea
          className={classNames('form-control', {
            'is-invalid': isInvalid
          })}
          placeholder={field.label}
          id={fieldKey}
          value={value}
          rows={field.rows || 5}
          onChange={(e) => onChange(e.target.value, fieldKey)}
          onBlur={(e) => onBlur(e.target.value, fieldKey)}/>
      );
    case 'boolean':
      return (
        <div className="text-right">
          <Checkbox checked={value} label={field.label} name={fieldKey} onChange={(e) => {
            onChange(e.target.checked, fieldKey)
          }}/>
        </div>
      );
    case 'select':
      let options = getSelectOptions(field, props) ? getSelectOptions(field, props): [];

      return (
        <Select
          className={classNames('form-control', {
            'is-invalid': isInvalid
          })}
          value={getSelectValue(value, options, field)}
          isClearable={true}
          isSearchable={true}
          defaultValue={null}
          options={options}
          name={fieldKey}
          getOptionValue={(o) => getOptionValue(o, field)}
          formatGroupLabel={formatGroupLabel}
          onChange={(e) => onChange(e? field.value? e[field.value]: e.value: null, fieldKey)}
        ></Select>
      )
     case 'score':
      let scoreOptions = getScoreOptions(field);

      return (
        <Select
          className={classNames('form-control', {
            'is-invalid': isInvalid
          })}
          value={getSelectValue(value, scoreOptions, field)}
          isClearable={true}
          isSearchable={true}
          defaultValue={null}
          options={scoreOptions}
          name={fieldKey}
          getOptionValue={(o) => getOptionValue(o, field)}
          formatGroupLabel={formatGroupLabel}
          onChange={(e) => onChange(e? field.value? e[field.value]: e.value: null, fieldKey)}
        ></Select>
      )

    case 'uploadUserPhoto':
      return (
        <UploadUserPhoto
          onUpdatePhoto={(data) => onChange(data.userPhotoUrl, 'photo')}
        />
      );

    case 'formType':
      return (
        <FormTypePicker
          value={value}
          onChange={(e) => onChange(e, fieldKey)}
          onBlur={(e) => onBlur(e, fieldKey)}
        />
      )
    case 'colorLabels':
      return (<ColorLabels onChange={(e) => onChange(e, fieldKey)} value={value}/>)

  }
};

const initializeProperties = (props, fields) => {
  let initialValues = props.initialValues || {};

  let properties = {};
  Object.keys(fields).forEach(fieldKey => {
    properties[fieldKey] = initialValues[fieldKey] || '';
  });

  return properties
};

class CustomForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      properties: initializeProperties(props, props.config.fields),
      validation: validateForm(props.config, initializeProperties(props, props.config.fields)),
      touched: {},
      submitted: false,
      isLoading: false
    }
  }

  componentDidMount() {
    // const fieldsObj = this.props.config.fields;
    // const fieldsKeys = Object.keys(this.props.config.fields);

    // fieldsKeys.reduce((acc, fieldKey) => {
    //   if (fieldsObj[fieldKey].type == 'select') {
    //     let options = getSelectOptions(fieldsObj[fieldKey], this.props);
    //
    //     if (this.state.properties[fieldKey] != '') {
    //       this.onBlur(this.state.properties[fieldKey], fieldKey);
    //     }
    //     else {
    //       this.onBlur(options[0][fieldsObj[fieldKey].value], fieldKey);
    //     }
    //   }
    //
    //   return acc;
    // }, []);

    if(!this.props.initialValues)
      this.form.elements[0].focus()
  }

  // ----------
  // Methods
  // ----------

  validate(value, fieldKey) {
    return validateForm(this.props.config, {
      ...this.state.properties,
      [fieldKey]: value
    })
  }

  showValidationError(fieldKey) {
    return (this.state.submitted || this.state.touched[fieldKey])
      ? this.state.validation.fieldsValidation[fieldKey]
      : false
  }

  onChange(value, fieldKey) {
    this.setState({
      properties: {
        ...this.state.properties,
        [fieldKey]: value
      },
      validation: this.validate(value, fieldKey),
    }, () => {
      this.props.onChange && this.props.onChange(this.state.properties)
    });


  }

  onBlur(value, fieldKey) {
    this.setState({
      properties: {
        ...this.state.properties,
        [fieldKey]: value
      },
      validation: this.validate(value, fieldKey),
      touched: {
        ...this.state.touched,
        [fieldKey]: true
      }
    })
  }


  isActive(field) {
    if(typeof field.isActive === 'function') {
      return field.isActive(this.props, this.state.properties)
    } else {
      return true;
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    if(this.state.validation.formValid) {
      if(this.props.submitAction) {
        this.setState({
          isLoading: true
        })
        this.props.submitAction(this.state.properties)
          .then(res => {
            this.setState({
              isLoading: false
            })
            this.props.onSuccess && this.props.onSuccess()
            return res
          })
          .catch(err => {
            this.setState({
              isLoading: false
            })
            return Promise.reject(err)
          })
      } else {
        this.props.onSubmit(this.state.properties)
      }
    } else {
      this.setState({
        submitted: true
      })
    }
  }

  // ----------
  // Render
  // ----------

  render() {
    let {config: {fields}} = this.props;

    return (
      <form onSubmit={this.handleSubmit.bind(this)} ref={el => this.form = el} autoComplete="off">
        {Object.keys(fields).map((fieldKey) =>
          this.isActive(fields[fieldKey]) && (
            <div className="form-group" key={fieldKey}>
              {fields[fieldKey].type !== 'boolean' &&
              <label htmlFor={fieldKey}>
                {fields[fieldKey].label}
              </label>
              }

              {
                getFieldByType(
                  fields[fieldKey],
                  fieldKey,
                  this.state.properties[fieldKey],
                  this.onChange.bind(this),
                  this.onBlur.bind(this),
                  this.props,
                  !!this.showValidationError(fieldKey)
                )
              }

              {
                this.showValidationError(fieldKey) &&
                  <div className="invalid-feedback">{this.showValidationError(fieldKey)}</div>
              }
            </div>
        ))}
        <div className="text-right">
          {this.props.cancelAvailable &&
            <button type="button" onClick={() => this.props.onCancel && this.props.onCancel()} className={'button small danger'} style={{marginRight: '10px'}}>
              Cancel
            </button>
          }
          <LoadingButton
            primary
            isLoading={this.props.isLoading || this.state.isLoading}
            text={this.props.saveLabel || "Submit"}
            icon={this.props.saveIcon}
            small={this.props.saveSmall}
          />
        </div>
      </form>
    );
  }
}

CustomForm.propTypes = {
  config: PropTypes.shape({
    fields: PropTypes.objectOf(PropTypes.shape({
      type: PropTypes.string,
      rules: PropTypes.arrayOf(PropTypes.shape({
        message: PropTypes.string,
        test: PropTypes.func
      }))
    }))
  })
};

export default CustomForm;
